import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  concatMap,
  finalize,
  of,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthorityLoadingService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading.asObservable();
  private _isWaiting = new BehaviorSubject<boolean>(false);
  isWaiting$ = this._isWaiting.asObservable();

  showWaitingIndicator<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => {
        this._isWaiting.next(true);
      }),
      concatMap(() => obs$),
      finalize(() => {
        this._isWaiting.next(false);
      })
    );
  }

  showLoadingIndicator<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => {
        this._isLoading.next(true);
      }),
      concatMap(() => obs$),
      finalize(() => {
        this._isLoading.next(false);
      })
    );
  }
}
